import React, { Fragment } from "react";
import {
  useQuery,
} from "@apollo/client";
import Main from "../components/main";

import moment from "moment-mini";
import { Container, Row, Col } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { console, window } from "window-or-global";
import { useAsyncSetState } from "use-async-setstate";

import { getCurrentUserQuery } from "../components/logic/user";
import Loader from "../components/bootstrap/loader";
import { getCountryByCode } from "../components/utils/countries";


import planImage0 from "../images/bea/bea_levels-01.svg";
import planImage1 from "../images/bea/bea_levels-02.svg";
import planImage2 from "../images/bea/bea_levels-03.svg";
import planImage3 from "../images/bea/bea_levels-04.svg";

import PortalHeader from "../components/portal/portal-header";

import ChangeDetails from "../components/portal/change-details";
import ChangePassword from "../components/portal/change-password";
import ChangePlan from "../components/portal/change-plan";
import CancelPlan from "../components/portal/cancel-plan";
import SEO from "../components/section/seo";
import AcceptTNC from "../components/bootstrap/tnc";
import DeviceItem from "../components/portal/device/device-item";
import AddDevice from "../components/portal/device/add-device";

export default function Portal() {
  return (<Main>
    <SEO title="Be Accomodation - Portal" />
    <PortalHeader />
    <PortalIndex />
  </Main>)
}


function PortalIndex(props) {
  const currentUserQuery = useQuery(getCurrentUserQuery);
  if (currentUserQuery.loading) {
    return <Loader />;
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;

  if (!user) {
    return window.location = "/login";
  }

  return (
    <PortalAccount user={user} {...props} />
  );
}

function PortalAccount({ user }) {
  console.log({ user })
  const [showChangeDetails, setShowChangeDetails] = useAsyncSetState(false);
  const [showChangePassword, setShowChangePassword] = useAsyncSetState(false);
  const [showChangePlan, setShowChangePlan] = useAsyncSetState(false);
  const [showCancelPlan, setShowCancelPlan] = useAsyncSetState(false);
  const [showAddDevice, setAddDevice] = useAsyncSetState(false);

  let activePlan, activeRenewal, currentProduct, totalPeriod, remainingPeriod, percentPeriod, percentUsage, status;
  const selectedCountry = getCountryByCode(user.mobileCountry || "au");

  const [model, supportID] = (window.atob(user.id) || "").split(":");

  activePlan = user.activePlan || {};
  // console.log({activePlan, renewal: user.activeRenewal, user})
  let planEnd, planStart;
  if (!user.activeRenewal && !user.activePlan) {
    activeRenewal = {
      start: moment((user.activeRenewal || {}).start, "YYYY-MM-DD HH:mm:ssZ"),
      end: moment((user.activeRenewal || {}).end, "YYYY-MM-DD HH:mm:ssZ"),
      status: (user.activeRenewal || {}).status,
      products: (user.activeRenewal || {}).products || [],
    };
    planStart = moment(user?.activePlan?.start, "YYYY-MM-DD HH:mm:ssZ");
    planEnd = moment(user?.activePlan?.end, "YYYY-MM-DD HH:mm:ssZ");
    status = activeRenewal.status;
    currentProduct = (activeRenewal?.products?.edges || [])[0]?.node || {};
    totalPeriod = planEnd.diff(planStart, "hours");
    remainingPeriod = planEnd.diff(moment(), "hours");

    percentPeriod = 100 - parseInt((remainingPeriod / totalPeriod) * 100) || 0;
    percentPeriod = percentPeriod > 100 ? 100 : percentPeriod;
    percentUsage = parseInt((activePlan.usage / activePlan.total) * 100) || 0;
    percentUsage = percentUsage > 100 ? 100 : percentUsage;
  }

  if (user.disabled) {
    status = 1;
  }

  if ((user.activeRenewal || {}).status === 6) {
    status = 6;
  }

  const devices = (((user || {}).devices || {}).edges || []).map(({ node }) => node);

  const rawManufacturers = devices.map(({ manufacturer }) => manufacturer || "Others");
  const manufacturers = (rawManufacturers || []).filter((v, i, a) => a.indexOf(v) === i);
  const manufacturerDataSet = manufacturers.map((m) => {
    let count = 0;
    rawManufacturers.forEach((data) => {
      if (m === data) {
        count += 1;
      }
    })
    return count;
  })

  const { addresses } = user;
  const userAddress = (addresses?.edges || [])[0]?.node;
  const { streetNo, address, suburb, state, postCode, country } = userAddress || {};
  let planImage = planImage0;
  if (percentPeriod >= 15) {
    planImage = planImage1;
  }

  if (percentPeriod >= 45) {
    planImage = planImage2;
  }

  if (percentPeriod >= 65) {
    planImage = planImage3;
  }

  if (percentPeriod >= 85) {
    planImage = planImage3;
  }

  function getPlanDescription(planEnd, user) {
    if (user.scheduledRenewal) {
      let scheduledProduct =
        (user.scheduledRenewal.products ||
          [])[0] || {}

      return `Your plan will change to ${scheduledProduct.description} for $${scheduledProduct.value} on ${planEnd.format("Do of MMMM YYYY")}.`;
    }

    switch (status) {
      case undefined:
        return "You do not have an active plan."
      case 1:
      case 2:
        return "Your plan is inactive"
      case 3:
        return `Your plan will be disabled on the ${planEnd.format("Do of MMMM YYYY")}`;
      case 6:
        return user.activeRenewal.products.edges[0].node.description
      default:
        return `Your next automatic payment will be for $${currentProduct.value || 0} on ${planEnd.format("Do of MMMM YYYY")}.`
    }
  }

  return (
    <div>
      {showChangeDetails && <ChangeDetails user={user} show onClose={() => setShowChangeDetails(false)} />}
      {showChangePassword && <ChangePassword user={user} show onClose={() => setShowChangePassword(false)} />}
      {showChangePlan && <ChangePlan user={user} show onClose={() => setShowChangePlan(false)} />}
      {showCancelPlan && <CancelPlan user={user} show onClose={() => setShowCancelPlan(false)} />}
      {(user.requireAcceptTNC || user.requireChangePassword) && <AcceptTNC user={user} />}
      {showAddDevice && <AddDevice show user={user} onClose={() => setAddDevice(false)} />}
      <Container>
        <Row>
          <Col className="vw-portal-account-title">
            {`Welcome ${user.firstName} ${user.lastName}!`}
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={6} className="mb-3">
            <div className="panel-container h-100">
              <div className="panel-header">User Details</div>
              <div className="panel-body h-100">
                <div className="user-details">
                  <div className="user-label">Name</div>
                  <div className="user-info">{`${user.firstName} ${user.lastName}`}</div>

                  <div className="user-label">Mobile</div>
                  <div className="user-info">{`${user.mobileNumber || ""}`}</div>

                  <div className="user-label">Email</div>
                  <div className="user-info">{`${user.email}`}</div>
                </div>
                <Row className="mt-3 pt-3 row-border-top">
                  <Col xs={12} sm={6}>
                    <a
                      onClick={async () => setShowChangePassword(true)}
                      className="portal-panel-button">
                      {"Change Password"}
                    </a>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <div className="panel-container h-100">
              <div className="panel-header">
                {`Your Devices ${user.deviceLimit ? `- ${user.devices.edges.length}/${user.deviceLimit}` : ""}`}
              </div>
              <div className="panel-body period h-100">
                <Container className="no-gutters">
                  <Row>
                    <Col className="vw-portal-account-devices">
                      {user.devices && user.devices.edges.map(({ node }) => (
                        <DeviceItem
                          key={`device1-${node.id}`}
                          device={node}
                        />
                      ))}
                    </Col>
                  </Row>
                </Container>
              </div>
              {user.devices && user.devices.edges.length < user.deviceLimit && <Container>
                <Row className="py-3 portal-button-row row-border-top">
                  <Col xs={12} sm={6} className="d-flex justify-content-flex-end ml-auto">
                    <a
                      onClick={async () => setAddDevice(true)}
                      className="portal-panel-button pr-2">
                      {"Add Device"}
                    </a>
                  </Col>
                </Row>
              </Container>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={6} className="mb-3">
            <div className="panel-container h-100">
              <div className="panel-header">Account Details</div>
              <div className="panel-body h-100">
                <div className="user-details">

                  <div className="user-label">Device Limit</div>
                  <div className="user-info">{user?.deviceLimit}</div>

                  <div className="user-label">Status</div>
                  <div className="user-info">{user?.disabled ? "Inactive" : "Active"}</div>

                  <div className="user-label">Support ID</div>
                  <div className="user-info">{`${supportID}`}</div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} lg={6} xl={6} className="mb-3">
            <div className="panel-container h-100">
              <div className="panel-header">
                <div>
                  Usage
                </div>

              </div>
              <div className="panel-body period h-100">
                <Doughnut
                  plugins={[ChartDataLabels]}
                  data={
                    {
                      datasets: [{
                        data: (activePlan || {}).unlimited ? [1] : [Number((activePlan || {}).usage) || 0, (Number((activePlan || {}).total) - Number((activePlan || {}).usage) || 0)],
                        backgroundColor: ["rgb(246, 142, 45)", "rgb(255, 237, 233)"],
                      }],
                      labels: (activePlan || {}).unlimited ? ["Unlimited"] : ["Data Usage", "Remaining Data"],
                    }
                  }
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                      position: "bottom",
                    },
                    plugins: {
                      datalabels: {
                        backgroundColor: function (context) {
                          return context.dataset.backgroundColor;
                        },
                        color: "white",
                        display: false,
                        font: {
                          weight: "bold",
                        },
                      },
                    },
                    scales: {
                      display: {},
                      gridLines: {
                        lineWidth: 0,
                      },
                      ticks: {
                        fontFamily: "SFUIDisplay",
                      },
                    },
                    pan: {
                      enabled: true,
                      mode: "x",
                    },
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
